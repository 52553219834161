const data = [
    {
        "id": 1,
        "image": "assets/Client_Photos/NEW_Client_Photos/Latto.png",
        "alt": "Latto 777",
        "artistName": "Latto",
        "text": "Latto is one of the kindest and most authentic, down-to-earth artists I've ever met. I had the pleasure and privledge of working on her sophmore album, 777.",
        "hover": ""
    },
    {
        "id": 2,
        "image": "assets/Client_Photos/NEW_Client_Photos/Dreamville.png",
        "alt": "Dreamville: Revenge of the Dreamer's III",
        "artistName": "Dreamville",
        "text": "Dreamville: Over 100 artists and producers were present at the Revenge of the Dreamer's III sessions and impromptu magic was made 24/7 for 2 weeks. It was like being in a fantasy world for everybody involved, artists included.",
        "hover": ""
    },
    {
        "id": 3,
        "image": "assets/Client_Photos/NEW_Client_Photos/Mike-Will-Made-It.png",
        "alt": "Mike Will Sessions",
        "artistName": "Mike Will Made It",
        "text": "I have been fortunate enough to work probably 200+ sessions with Mike Will over the years, across numerous projects with various artists. There were a few years where the work was non-stop every night for months straight.",
        "hover": ""
    },
    {
        "id": 4,
        "image": "assets/Client_Photos/NEW_Client_Photos/jeezy.png",
        "alt": "Young Jeezy: Trap or Die 3",
        "artistName": "Jeezy",
        "text": " Working with Jeezy meant a lot because I was a fan since before I even started engineering. I loved that his laugh was the exact same in person as on a record.",
        "hover": ""
    },
    {
        "id": 5,
        "image": "assets/Client_Photos/NEW_Client_Photos/Neyo.png",
        "alt": "Neyo various sessions over 6+ years.",
        "artistName": "Neyo",
        "text": "Worked with Neyo for over 6+ years and these sessions have always been special. Getting to watch Neyo write a song, is like getting to watch a master at work and I'd be left dumb-founded with inspiration every time.",
        "hover": ""
    },
    {
        "id": 6,
        "image": "assets/Client_Photos/NEW_Client_Photos/Nth_Power.png",
        "alt": "Nth Power sessions.",
        "artistName": "The Nth Power",
        "text": "The Nth Power is a band I worked with for a week long sprint of sessions. I'll never forget these sessions because I was completley blown away they are some of the most musical talented individuals I've ever come across in my life.",
        "hover": ""
    }
]

export default data;